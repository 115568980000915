import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Navigate, Route, Routes as Switch } from 'react-router-dom';
import { If } from '~/components';
import { FluidTheme, isEmpty } from '~/modules';
import {
  AccountDetails,
  AddBalance,
  Campaigns,
  ClientAccounts,
  ClientRegister,
  ClientSearch,
  Clients,
  CompanyRegister,
  Exports,
  Login,
  ProductDetails,
  ProductRegister,
  Products,
  ProductsPermission,
  QrCode,
  Shops,
  SideAccountRegister,
  UpdateLimit,
  Uploads,
  UploadsDetails,
  UserActivationError,
  UserDetails,
  Users,
  UsersRanking,
} from '~/scenes';
import { Storage } from '~/services';
import { UserRoles, showAlert, useStores } from '~/utils';
import { Routes } from './routing';

const RoutesContainer: React.FC = () => {
  const {
    user: { isLogged, getUserLogged, user },
    theme: { theme },
  } = useStores();
  const tokenStorage = Storage.getToken();
  const [token, setToken] = useState<Token>(tokenStorage);
  const [themeVerificated, setThemeVerificated] = useState<FluidTheme>(theme);

  const getUserDetails = async () => {
    try {
      await getUserLogged();
    } catch ({ message }) {
      showAlert({ message });
    }
  };

  useEffect(() => {
    if (token) {
      getUserDetails();
    }
  }, [token]);

  useEffect(() => {
    setToken(tokenStorage);
    setThemeVerificated(theme);
  }, [isLogged, theme]);

  return (
    <If condition={!isEmpty(themeVerificated)}>
      <Switch>
        {isEmpty(token) && (
          <>
            <Route path="*" element={<Navigate to={Routes.LOGIN} />} />
            <Route path={Routes.LOGIN} element={<Login />} />
            <Route
              path={Routes.USER_ACTIVATION_ERROR}
              element={<UserActivationError />}
            />
          </>
        )}
        {!isEmpty(token) && (
          <>
            {(user?.role === UserRoles.ADMIN ||
              user?.role === UserRoles.SPECTATOR) && (
              <>
                <Route path="*" element={<Navigate to={Routes.CLIENTS} />} />
                <Route path={Routes.CLIENTS} element={<Clients />} />
                <Route
                  path={Routes.COMPANY_REGISTER}
                  element={<CompanyRegister />}
                />
                <Route
                  path={Routes.CLIENT_REGISTER}
                  element={<ClientRegister />}
                />
                <Route path={Routes.CLIENT_SEARCH} element={<ClientSearch />} />
                <Route
                  path={Routes.CLIENT_ACCOUNTS}
                  element={<ClientAccounts />}
                />
                <Route
                  path={Routes.CLIENT_ACCOUNT_DETAILS}
                  element={<AccountDetails />}
                />
                <Route path={Routes.ADD_BALANCE} element={<AddBalance />} />
                <Route path={Routes.UPDATE_MILIT} element={<UpdateLimit />} />
                <Route
                  path={Routes.SIDE_ACCOUNT_REGISTER}
                  element={<SideAccountRegister />}
                />
                <Route path={Routes.USERS} element={<Users />} />
                <Route path={Routes.USER_DETAILS} element={<UserDetails />} />
                <Route
                  path={Routes.PRODUCTS_PERMISSION}
                  element={<ProductsPermission />}
                />
                <Route path={Routes.PRODUCTS} element={<Products />} />
                <Route
                  path={Routes.PRODUCT_REGISTER}
                  element={<ProductRegister />}
                />
                <Route
                  path={Routes.PRODUCT_DETAILS}
                  element={<ProductDetails />}
                />
                <Route path={Routes.FILES} element={<Uploads />} />
                <Route
                  path={Routes.FILES_DETAILS}
                  element={<UploadsDetails />}
                />
                <Route path={Routes.EXPORTS} element={<Exports />} />
              </>
            )}
            {user?.role === UserRoles.RH_LOJA && (
              <>
                <Route path="*" element={<Navigate to={Routes.QR_CODE} />} />
                <Route path={Routes.QR_CODE} element={<QrCode />} />
                <Route path={Routes.USERS_RANKING} element={<UsersRanking />} />
              </>
            )}
            {user?.role === UserRoles.RH_CENTRAL && (
              <>
                {/* Central HR */}
                <Route path="*" element={<Navigate to={Routes.SHOPS} />} />
                <Route path={Routes.SHOPS} element={<Shops />} />
                <Route path={Routes.CAMPAIGNS} element={<Campaigns />} />
                <Route path={Routes.QR_CODE} element={<QrCode />} />
                <Route path={Routes.USERS_RANKING} element={<UsersRanking />} />
              </>
            )}
          </>
        )}
      </Switch>
    </If>
  );
};

export { Routes };
export default observer(RoutesContainer);
