import { FC } from 'react';
import { TitlePage, UsersRankingRow } from '~/components';
import { observer } from '~/modules';
import { UserRoles, usersRankingColumns } from '~/utils';
import {
  ContentBox,
  TopBox,
  UsersRankingPaginatedList,
  Wrapper,
} from './styles';

type Props = {
  isUsersRankingLoading: boolean;
  usersRankingList: ListResponse<UsersRanking>;
  onChangePage(page: number, locationCode?: string): void;
  locationCode?: string;
  userRole: UserRoles;
  onPressFilter(locationCode: string): Promise<void> | void;
};

export const UsersRanking: FC<Props> = ({
  isUsersRankingLoading,
  usersRankingList,
  onChangePage,
  userRole,
  onPressFilter,
  locationCode,
}) => {
  return (
    <Wrapper
      titlePage="Ranking"
      labelFilter={
        userRole === UserRoles.RH_CENTRAL ? 'Código da Loja' : undefined
      }
      onPressFilter={
        userRole === UserRoles.RH_CENTRAL ? onPressFilter : undefined
      }
    >
      <ContentBox>
        <TopBox>
          <TitlePage>Ranking de usuários</TitlePage>
        </TopBox>
        <UsersRankingPaginatedList
          columns={usersRankingColumns}
          list={usersRankingList.content}
          totalPages={usersRankingList.paging?.totalPages}
          currentPage={usersRankingList.paging?.page}
          isLoading={isUsersRankingLoading}
          onChangePage={(page) => onChangePage(page, locationCode)}
          renderList={(user: UsersRanking) => (
            <UsersRankingRow
              name={user.name}
              position={user.locationPosition}
              points={user.points}
              key={`${user.name}` + `${user.locationPosition}`}
            />
          )}
          titleEmptyData="Sem usuários cadastrados"
        />
      </ContentBox>
    </Wrapper>
  );
};

export default observer(UsersRanking);
