import { FC, useEffect, useState } from 'react';
import { observer } from '~/modules';
import { UserRoles, showAlert, useStores } from '~/utils';
import UsersRanking from './UsersRanking';

export const UsersRankingContainer: FC = () => {
  const {
    users: { usersRankingList, getUsersRanking, setLocationCode, locationCode },
    user: { user },
  } = useStores();
  const [isUsersRankingLoading, setIsUsersRankingLoading] = useState(false);

  const onGetUsersRanking = async (values: GetUsersRankingProps) => {
    setIsUsersRankingLoading(true);
    try {
      await getUsersRanking(values);
    } catch ({ message }) {
      showAlert({ message });
    } finally {
      setIsUsersRankingLoading(false);
    }
  };
  const handleSetLocationCode = async ({
    locationCode,
  }: {
    locationCode: string;
  }) => {
    setLocationCode(locationCode);
    await onGetUsersRanking({ locationCode });
  };

  useEffect(() => {
    if (user.role === UserRoles.RH_CENTRAL) {
      return;
    }
    onGetUsersRanking({});
  }, []);

  return (
    <UsersRanking
      isUsersRankingLoading={isUsersRankingLoading}
      usersRankingList={usersRankingList}
      onChangePage={(page, locationCode) =>
        onGetUsersRanking({ page, locationCode })
      }
      locationCode={
        user.role === UserRoles.RH_CENTRAL
          ? locationCode
          : user?.metadata?.locationCode
      }
      userRole={user.role}
      onPressFilter={async (locationCode) => {
        locationCode && (await handleSetLocationCode({ locationCode }));
      }}
    />
  );
};

export default observer(UsersRankingContainer);
