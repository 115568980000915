import { useEffect, useState } from 'react';
import { observer, useNavigate } from '~/modules';
import { Routes } from '~/routes';
import { UserRoles, showAlert, useStores } from '~/utils';
import QrCode from './QrCode';

export const QrCodeContainer = () => {
  const navigate = useNavigate();
  const {
    qrCodes: {
      getQrCodes,
      qrCodesList,
      generateQrCode,
      locationCode,
      setLocationCode,
    },
    user: { user },
  } = useStores();

  const [areQrCodesLoading, setAreQrCodesLoading] = useState(true);

  const handleNavigate = (route: Routes) => navigate(route);

  const onGetQrCode = async (values: GetQrCodeProps) => {
    setAreQrCodesLoading(true);
    try {
      await getQrCodes(values);
    } catch ({ message }) {
      showAlert({ message });
    } finally {
      setAreQrCodesLoading(false);
    }
  };

  const onGenerateQrCode = async (locationCode: string) => {
    setAreQrCodesLoading(true);
    try {
      await generateQrCode(locationCode);
    } catch ({ message }) {
      showAlert({ message });
    } finally {
      onGetQrCode({ locationCode });
    }
  };

  const handleSetLocationCode = async ({
    locationCode,
  }: {
    locationCode: string;
  }) => {
    setLocationCode(locationCode);
    await onGetQrCode({ locationCode });
  };

  useEffect(() => {
    if (user.role === UserRoles.RH_CENTRAL && !locationCode) {
      setAreQrCodesLoading(false);
      return;
    }
    onGetQrCode({ locationCode });
  }, []);

  return (
    <QrCode
      areQrCodesLoading={areQrCodesLoading}
      qrCodesList={qrCodesList}
      onChangePage={(page, locationCode) => onGetQrCode({ page, locationCode })}
      onGenerateQrCode={(locationCode) => onGenerateQrCode(locationCode)}
      onNavigate={handleNavigate}
      userRole={user.role}
      locationCode={
        user.role === UserRoles.RH_CENTRAL
          ? locationCode
          : user?.metadata?.locationCode
      }
      onPressFilter={async (locationCode) => {
        locationCode && (await handleSetLocationCode({ locationCode }));
      }}
    />
  );
};

export default observer(QrCodeContainer);
