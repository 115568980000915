import { QrCodeApi } from '~/api';
import { action, makeObservable, observable } from '~/modules';

export default class QrCodesStore {
  @observable
  qrCodesList: ListResponse<QrCode> = {};

  @observable
  locationCode = '';

  constructor() {
    makeObservable(this);
  }

  @action
  getQrCodes = async (values: GetQrCodeProps): Promise<void> => {
    this.qrCodesList = await QrCodeApi.getQrCodes(values);
  };

  @action
  generateQrCode = async (locationCode: string): Promise<void> => {
    await QrCodeApi.generateQrCode({ locationCode });
  };

  @action
  setLocationCode = (locationCode: string) => {
    this.locationCode = locationCode;
  };
}
