import { ListPagination, ResponseError } from '~/utils';
import request from '../request';

export const getQrCodes = async ({
  page = ListPagination.INITIAL_PAGE,
  size = ListPagination.SIZE,
  locationCode,
}: GetQrCodeProps): Promise<ListResponse<QrCode>> => {
  try {
    const { data } = await request.get('/qrcode', {
      params: {
        page,
        size,
        locationCode,
      },
    });
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const generateQrCode = async (locationCode: GenerateQrCodeProps) => {
  try {
    await request.post(`/qrcode`, locationCode);
  } catch (error) {
    throw new ResponseError(error);
  }
};
