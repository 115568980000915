export enum UploadRoles {
  INACTIVATE = 'INACTIVATE',
  RECHARGE = 'RECHARGE',
  LIMIT_UPDATE_SCHEDULING = 'LIMIT_UPDATE_SCHEDULING',
}

export enum UploadStatus {
  PROCESSING = 'PROCESSING',
  PROCESSED_SUCCESS = 'PROCESSED_SUCCESS',
  PROCESSED_ERROR = 'PROCESSED_ERROR',
}

export enum UploadStatusPt {
  PROCESSING = 'Processando',
  PROCESSED_SUCCESS = 'Sucesso',
  PROCESSED_ERROR = 'Erro',
}

export enum UploadRolesPt {
  INACTIVATE = 'Inativações',
  RECHARGE = 'Recargas',
  LIMIT_UPDATE_SCHEDULING = 'Agendamento de Limite Compra + Assaí',
}
